@tailwind base;
@tailwind components;
@tailwind utilities;


  @font-face {
    font-family: "Haptik_Medium";
    src: local("Haptik_Medium"),
     url("../public/fonts/Haptik_Medium.ttf") format("truetype");
     font-display: swap;
    }


body {
  background-color: black;
  -webkit-animation-timing-function: linear; 
  animation-timing-function: linear;

}


    .intro{
      animation: hide 0.7s linear;
      animation-delay: 4.3s;
     animation-fill-mode: both;
     -webkit-animation: hide 0.7s linear;
     -webkit-animation-delay: 4.3s;
    -webkit-animation-fill-mode: both;
    }
  
    @keyframes hide {
    0%,75% { opacity:1;
    }
  0%,99% {scale:1}
      100% {opacity:0;
      scale: 0}
      
    }
    .transition{
      animation: reveal 0.7s linear;
      animation-delay: 4.3s;
     animation-fill-mode: both;
     -webkit-animation: reveal 0.7s linear;
     -webkit-animation-delay: 4.3s;
    -webkit-animation-fill-mode: both;
    }

    @keyframes reveal {
    0%,75% { opacity:0}
      100% {opacity:1}
    }

    



.text-gradient {
  background: linear-gradient(rgba(196,181,253,1), rgb(147 197 253));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


@keyframes bounce {
 
  to {
    scale:.8;
    
}
  
}
.hover\:bounce:hover {
 
  animation: bounce 2.5s linear ;
  animation-delay: 0.1s;
}



  @keyframes pulser {

    50% {
        opacity: .75;
        scale: .8
    }
}
.pulser1 {
    animation: pulser 3s linear infinite;
}
.pulser2 {
  animation: pulser 5s linear infinite;
}
.pulser3 {
  animation: pulser 7s linear infinite;
}



h2 {
  font-size: calc(1em + 1vw )
}

h3 {
  font-size: calc(0.8em + 1.2vw );
}

h4 {
  font-size: calc(1.25em + 2vw );
}


h5 {
  font-size: calc(1em + 2vw );
}
p {
  font-size: calc(0.6em + 0.8vw);
}




h6 {

  font-size: calc(0.5em + 1vw );
}




h3 span {
  
  opacity: 0;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  width: 100%
 
  
}

span:nth-child(1) {
  animation: fade-in 0.8s  forwards linear;
  animation-delay: 5s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5s;
}

span:nth-child(2) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.05s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.05s;
}

span:nth-child(3) {
  animation: fade-in 0.8s  forwards linear;
  animation-delay: 5.1s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.1s;
}

span:nth-child(4) {
  animation: fade-in 0.8s  forwards linear;
  animation-delay: 5.15s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.15s;
}

span:nth-child(5) {
  animation: fade-in 0.8s  forwards linear;
  animation-delay: 5.2s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.2s;
}

span:nth-child(6) {
  animation: fade-in 0.8s  forwards linear;
  animation-delay: 5.25s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.25s;
}

span:nth-child(7) {
  animation: fade-in 0.8s  forwards linear;
  animation-delay: 5.3s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.3s;
}

span:nth-child(8) {
  animation: fade-in 0.8s  forwards linear;
  animation-delay: 5.35s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.35s;
}

span:nth-child(9) {
  animation: fade-in 0.8s  forwards linear;
  animation-delay: 5.4s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.4s;
}

span:nth-child(10) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.45s;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.45s;
}

span:nth-child(11) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.5s ;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.5s;
}


span:nth-child(12) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.55s  ;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.55s;
}

span:nth-child(13) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.6s  ;
  -webkit-animation: fade-in 0.8s forwards linear;
  -webkit-animation-delay: 5.6s;
}

span:nth-child(14) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.65s  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 5.65s;
}

span:nth-child(15) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.7s  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 5.7s;
}

span:nth-child(16) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.75s  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 5.75s;
}

span:nth-child(17) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.8s  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 5.8s;
}

span:nth-child(18) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.85s  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 5.85s;
}

span:nth-child(19) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.9s  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 5.9s;
}
span:nth-child(20) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 5.95s
  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 5.95s;
}

span:nth-child(21) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6s ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6s;
}

span:nth-child(22) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.05s
  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.05s;
}

span:nth-child(23) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.1s ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.1s;
}

span:nth-child(24) {
  animation: fade-in 0.8s forwards linear;
  animation-delay:fade-in  6.15s ;
  -webkit-animation:fade-in 0.8s forwards linear;
  -webkit-animation-delay: 6.15s;
}

span:nth-child(25) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.2s
  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.2s;
}

span:nth-child(26) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.25s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.25s;
}
span:nth-child(27) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.3s
  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.3s;
}
span:nth-child(28) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.35s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.35s;
}
span:nth-child(29) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.4s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.4s;
}
span:nth-child(30) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.45s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.45s;

}
span:nth-child(31) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.5s
  ;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.5s;
}
span:nth-child(31) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.55s;
  -webkit-animation:fade-in 0.8s forwards linear;
  -webkit-animation-delay: 6.55s;
}

span:nth-child(32) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.6s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.6s;
}

span:nth-child(33) {
  
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.65s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.65s;
}

span:nth-child(34) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.7s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.7s;
}

span:nth-child(35) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.75s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.75s;
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    
  }
}
span:nth-child(36) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.80s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.8s;
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    
  }
}
span:nth-child(37) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.85s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.85s;
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    
  }
}
span:nth-child(38) {
  animation: fade-in 0.8s forwards linear;
  animation-delay: 6.9s;
  -webkit-animation:fade-in  0.8s forwards linear;
  -webkit-animation-delay: 6.9s;
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    
  }
}



.glass {
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}


.card {
  position: relative;
  display: inline-block;
}

.card .card-text {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: all 0.3s ease;
}

.card:hover .card-text {
  display: block;
}
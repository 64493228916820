.boule_1 {
     position:relative;
     top: 5vh;
     z-index: 1;
     width: 5vh;
     height:5vh;
     background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
     border-radius: 100%;  
     animation: move_left 1s linear;
     animation-fill-mode: both;
 }

 .boule_2 {
    position:absolute;
    top: 15vh;
    right:0vh;
    z-index: 1;
    width: 5vh;
    height:5vh;
    background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
    border-radius: 100%; 
    animation: move_right 1.5s linear;
    animation-fill-mode: both; 
}

 .boule_3 {
    position:absolute;
    top: 25vh;
    z-index: 1;
    width: 5vh;
    height:5vh;
    background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
    border-radius: 100%;   
    animation: move_left 1.3s linear;
    animation-fill-mode: both; 
}

.boule_4 {
    position:absolute;
    top: 35vh;
    right:0vh;
    z-index: 1;
    width: 5vh;
    height:5vh;
    background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
    border-radius: 100%;
    animation: move_right 0.5s linear;
    animation-fill-mode: both;
}

.boule_5 {
    position:absolute;
    top: 55vh; 
    z-index: 1;
    width: 5vh;
    height:5vh;
    background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
    border-radius: 100%;  
    animation: move_left 1.5s linear;
    animation-fill-mode: both;
}

.boule_6 {
    position:absolute;
    bottom: 25vh;
    right:0vh;
    z-index: 1;
    width: 5vh;
    height:5vh;
    background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
    border-radius: 100%;
    animation: move_right 1.2s linear;
    animation-fill-mode: both; 
}

.boule_7 {
    position:absolute;
    bottom: 15vh; 
    z-index: 1;
    width: 5vh;
    height:5vh;
    background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
    border-radius: 100%;
    animation: move_left 0.5s linear;
    animation-fill-mode: both;
}

.boule_8 {
    position:absolute;
    bottom: 5vh;
    right:0vh;
    z-index: 1;
    width: 5vh;
    height:5vh;
    background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
    border-radius: 100%;
    animation: move_right 0.8s linear;
    animation-fill-mode: both;
}



@keyframes move_left {
    0%,99% {
        
       opacity:1;
    }
    100% {
        transform: translateX(200vh);
        opacity:0;       
    }
    
}
@keyframes move_right {
    0%,99% {
       
        opacity:1;       
    }
    100% {
        transform: translateX(-200vh);
        opacity:0;       
    }
    
}



    

/* Barre gradient TOP */
.barre_gauche_1 {
    position: absolute;
    left:0;
    top: 5vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-fill-mode: both;
 
}

.barre_gauche_2 {
    position: absolute;
    right:0;
    top: 10vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-delay: 0.1s;
 animation-fill-mode: both;
}




.barre_gauche_3 {
    position: absolute;
    left:0;
    top: 15vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-delay: 0.2s;
 animation-fill-mode: both;
}

.barre_gauche_4 {
    position: absolute;
    right:0;
    top: 20vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-delay: 0.3s;
 animation-fill-mode: both;
}
.barre_gauche_5 {
    position: absolute;
    left:0;
    top: 25vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-delay: 0.4s;
 animation-fill-mode: both;
}
.barre_gauche_6 {
    position: absolute;
    right:0;
    top: 30vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-delay: 0.5s;
 animation-fill-mode: both;
}
.barre_gauche_7 {
    position: absolute;
    left:0;
    top: 35vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-delay: 0.6s;
 animation-fill-mode: both;
}
.barre_gauche_8 {
    position: absolute;
    right:0;
    top: 40vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-delay: 0.7s;
 animation-fill-mode: both;
}
.barre_gauche_9 {
    position: absolute;
    left:0;
    top: 45vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-delay: 0.8s;
 animation-fill-mode: both;
}


/* Barre gradient BOTTOM */
.barre_gauche_10 {
    position: absolute;
    left:0;
    bottom: 5vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-fill-mode: both;
}

.barre_gauche_11 {
    position: absolute;
    right:0;
    bottom: 10vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-delay: 0.1s;
 animation-fill-mode: both;
}


.barre_gauche_12 {
    position: absolute;
    left:0;
    bottom: 15vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-delay: 0.2s;
 animation-fill-mode: both;
}

.barre_gauche_13 {
    position: absolute;
    right:0;
    bottom: 20vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-delay: 0.3s;
 animation-fill-mode: both;
}
.barre_gauche_14 {
    position: absolute;
    left:0;
    bottom: 25vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-delay: 0.4s;
 animation-fill-mode: both;
}
.barre_gauche_15 {
    position: absolute;
    right:0;
    bottom: 30vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-delay: 0.5s;
 animation-fill-mode: both;
}
.barre_gauche_16 {
    position: absolute;
    left:0;
    bottom: 35vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-delay: 0.6s;
 animation-fill-mode: both;
}
.barre_gauche_17 {
    position: absolute;
    right:0;
    bottom: 40vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_gauche 0.5s linear;
 animation-delay: 0.8s;
 animation-fill-mode: both;
}
.barre_gauche_18 {
    position: absolute;
    left:0;
    bottom: 45vh;
 width: 100%;
 height: 1px;
 background: linear-gradient(45deg, rgb(147 197 253),  rgba(196,181,253,1));
 animation: translate_droit 0.5s linear;
 animation-delay: 0.9s;
 animation-fill-mode: both;
}





@keyframes translate_gauche {
    to {
transform: translate(100%) ;
    }
    
}
@keyframes translate_droit {
    to {
transform: translate(-100%) ;
    }
    
}

.big_boule{
    position:absolute;

z-index: 0;
    width: 10vh;
    height:10vh;
    background: linear-gradient( rgb(147 197 253), rgba(196,181,253,1));
    border-radius: 100%;
    
   
    animation: bouboule_center 1.5s ;
    animation-fill-mode: both;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}
@keyframes bouboule_center {
    0%, 90% {
        
       scale:0
        
    }
    20%,30% {
        scale: 2
    }
    100% {
        
        scale:30;
        
        
    }
    
}

.boule_animate_1 {
    width: 25vh;
    height:25vh;
    border-radius: 100% ;
    background: white;
  animation: boule_center 0.5s;
  animation-delay: 1.5s;
  animation-fill-mode: both;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}
.boule_animate_2 {
    width: 25vh;
    height:25vh;
    border-radius: 100% ;
    background: black;
  animation: boule_center 0.5s;
  animation-delay: 3.5s;
  animation-fill-mode: both;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

@keyframes boule_center {
    0% {
    scale: 0;
  
    }
  
    100%{
    scale: 10;
  
   
    }
  
}



  .what {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: calc(3em + 8vw);
    color: black;
    animation: what 0.5s;
    animation-delay: 1.75s;
    animation-fill-mode: both;
    -webkit-animation: what 0.5s;
    -webkit-animation-delay: 1.75s;
    -webkit-animation-fill-mode: both;
}
  .you {
    position: relative;
    display: flex;
    font-size: calc(3em + 8vw);
    justify-content: center; 
    color: black;
    animation: you 0.5s;
    animation-delay: 2.25s;
    animation-fill-mode: both;
    -webkit-animation: you 0.5s;
    -webkit-animation-delay: 2.25s;
    -webkit-animation-fill-mode: both;
}
.think {
    position: relative;
    display: flex;
    font-size: calc(3em + 8vw);
    justify-content: center;
    color: black;
    animation: think 1s;
    animation-delay: 2.75s;
    animation-fill-mode: both;
    -webkit-animation: you 1s;
    -webkit-animation-delay: 2.75s;
    -webkit-animation-fill-mode: both;

}
@keyframes what {
   0% { scale: 0}
   1%,99% { scale: 1}
   100% { scale: 0}
}
@keyframes you {
    0% { scale: 0}
    1%,99% { scale: 1}
    100% { scale: 0}
 }
 @keyframes think {
    0% { scale: 0}
    1%,99% { scale: 1}
    100% { scale: 0}
 }

 .make {
display: flex;
    font-size: calc(3em + 8vw);
   
    color: rgb(244,244, 255);
    animation: make 1.5s;
    animation-delay: 3.75s;
    animation-fill-mode: both;
    -webkit-animation: make 1.5s;
    -webkit-animation-delay: 3.75s;
    -webkit-animation-fill-mode: both;
   position: absolute;
   z-index: 3;
 }

 @keyframes make {
    0% { scale: 0}
    1%,99.99% { scale: 1}
    100% { scale: 0}
 }



 .stroke_hover {
    display: inline-block;
    padding-bottom: 0.1rem; /* defines the space between text and underline */
    position: relative;
    
    
  }
  
  .stroke_hover::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: rgba(196,181,253,1);
    transition: width 0.5s ease-out;
    
  }
  .stroke_hover:hover::before {
    width: 100%;
    left: 0;
    right: auto;
  }

  

  .active {

    color: rgba(196,181,253,1)
   
  }



  .hvr-bounce-to-right {
   
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .hvr-bounce-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(196,181,253,1);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-bounce-to-right:hover, .hvr-bounce-to-right:focus, .hvr-bounce-to-right:active {
    color: white;
  }
  .hvr-bounce-to-right:hover:before, .hvr-bounce-to-right:focus:before, .hvr-bounce-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);

  }


  .nav_fluide {
    animation: fluide 0.25s linear;
   
    height: 100vh;
    width: 100vw;
    
  }

  @keyframes fluide {
0% {opacity:0}
100% {opacity:1}
  }

  .home_fluide {
    position:relative;
    animation: arrivage 250ms linear;
    animation-delay: 100ms;
    transform: translateY(-20vh);
    animation-fill-mode: both;
    
 
  }

  .realisations_fluide {
    position: relative;
    animation: arrivage 250ms linear;
    animation-delay: 200ms;
    transform: translateY(-10vh);
    animation-fill-mode: both;
  }

  .services_fluide {
    position:relative;
    animation: arrivage 250ms linear;
    animation-delay: 300ms;
    transform: translateY(0vh);
    animation-fill-mode: both;
  }

 
  .blockchain_fluide {
    position:relative;
    animation: arrivage 250ms linear;
    animation-delay: 400ms;
    transform: translateY(10vh);
    animation-fill-mode: both;
    
  }

  @keyframes arrivage {
    0% {
       
        transform: translateX(-100vw)}
        

    90% {
        transform: translateX(10vw);
       
    }
    100% {
        transform: translateX(0vw);
       
    }
  }




  .close_nav {
    animation : close_nav 0.25s linear;
}

@keyframes close_nav {
   
    0%, 50% { opacity:0}
    
 
 }
  
.open_nav {
    animation : open_nav 0.25s linear;
}
@keyframes open_nav {
   
   0%, 50% { opacity:0}
}


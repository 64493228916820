.box {
    position: relative;
  
    background: rgba(0,0,0,0.5);
    border-radius: 100%;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

.box::before {
    content: '';
    position: absolute;
    inset: -1px 5vw;
    background: linear-gradient(315deg, rgb(147 197 253), rgba(196,181,253,1));
    transition: 0.5s;
    animation: animate 4s linear infinite;
   
}
.box:hover::before {
    inset: 0px;
}

@keyframes animate {
    
    0% {
        transform: rotate(0deg);
}
0% {
    transform: rotate(360deg);
}
}

.box::after{
    content:'';
    position: absolute;
    inset: 1px;
    background: black;
    border-radius: 100%;
    z-index: 1;
}

.content {
    position: absolute;
    inset:15px;
    border: 1px solid black;
    z-index: 3;
    border-radius: 100%
}

